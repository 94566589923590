.timeline__item__compress {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  width: 100%;
}

.accounts__info {
  display: flex;
  flex-direction: row;
}

.accounts__info__image {
  flex-grow: 0;
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
  border-radius: 50%;
  object-fit: cover;
}

.accounts__info__names {
  margin-left: 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  max-height: 56px;
}

.accounts__info__display__name {
  display: flex;
  min-height: 28px;
  max-height: 28px;
  align-items: center;
  justify-content: flex-start;
}

.accounts__info__username {
  display: flex;
  min-height: 28px;
  max-height: 28px;
  align-items: center;
  justify-content: flex-start;
}

.status__contents {

}

.timeline__reblog__item__compress {
  display: flex;
  flex-direction: column;
  border: 1px solid #747bff;
  width: 100%;
}