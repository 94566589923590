.mobile {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
}

.m_canvas {
  flex-grow: 1;
}

.m_buttons {
  flex-basis: 60px;
}