.setting {
  display: flex;
}

.setting_main {
  flex-grow: 1;
}

.m_setting_menu {
  margin-right: 5px;
}

.w_setting_menu {
  margin-right: 5px;
  min-width: 200px;
}

@media (max-width: 767px) {

  .setting {
    display: flex;
    flex-direction: column;
  }

  .w_setting_menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .m_setting_menu {
    display: none;
  }
}